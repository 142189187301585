<template>
  <span>
    {{ item.useParentChannelId ? 'Группа родителя' : item.channelId }}
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
