<template>
  <div>
    <item-list-view
        :api="api"
        :headers="headers"
        id-key="code"
        title="Формы"
        search-placeholder="Поиск по заголовку"
        :allowed-search="false"
        :allowed-create="true"
        :allowed-remove="true"
        hide-default-footer
        :create-route="{ name: 'RequestForm', params: { id: 'new' }}"
        update-route="RequestForm"
        disable-pagination
        remove-title="Удаление статьи"
        remove-message="Вы действительно хотите удалить форму '<%= item.title %>'?"
    >
      <template v-slot:item.channelId="{ item }">
        <claims-list-item-channel :item="item" />
      </template>
      <template v-slot:item.comment="{ item }">
        <claims-list-item-comment :comment="item.comment" />
      </template>
    </item-list-view>
  </div>
  </template>

  <script>
  import api from '../../services/request-form'
  import ItemListView from '../../components/ItemListView'
  import ClaimsListItemChannel from '../../components/Claim/ClaimsListItemChannel.vue'
  import ClaimsListItemComment from '../../components/Claim/ClaimsListItemComment.vue'

  export default {
    components: {
      ClaimsListItemComment,
      ClaimsListItemChannel,
      ItemListView,
    },
    data: () => ({
      api: api,
      headers: [
        { text: 'Код', value: 'code' },
        { text: 'Название', value: 'title' },
        { text: 'Получатель', value: 'channelId' },
        { text: 'Комментарий', value: 'comment' },
        { text: '', value: 'action' }
      ],
    }),
  }
  </script>
