<template>
  <span class="claims-list-item-comment">
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    comment: {
      type: String,
    },
    length: {
      type: Number,
      default: 20
    }
  },
  computed: {
    text () {
      const string = this.comment || ''
      return [
          string.slice(0, 20),
          string.length > this.length ? '...' : ''
      ].join('')
    }
  }
}
</script>
